
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import { Logger } from "packs/common"
import Util from "packs/utils/Util"

@Options({
    components: {},
})
export default class SelectTag extends Vue {
    @Prop()
    options: string[]
    @Prop()
    defaultOption: string
    @Prop()
    needUnselect: boolean
    @Prop()
    backgroundColor: string
    @Prop()
    height: string

    // 変更情報を更新する場合、prefixNumを変更することで更新されるようになります。（ 一番カンタンなのは、Util.getSec() )
    @Prop()
    prefixNum: string

    @Prop()
    isDisabled: boolean

    @Prop()
    returnVal: string

    Util = Util

    public selectValue(e) {
        // Logger("update value has called." + e.target.value);
        if (this.returnVal) {
            this.$emit("selectValue", e.target.value, this.returnVal)
        } else {
            this.$emit("selectValue", e.target.value)
        }
    }

    @Watch(`defaultOption`)
    public updateDefaultOption() {
        Logger(`updateDefaultOptionが更新されました: ${this.defaultOption}`)
    }
}
